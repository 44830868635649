@import "colors";
@import "misc";

@mixin dropdown {
  display: flex;
  align-items: center;
  justify-content: center;

  a { 
    display: flex;
    align-items: center;
    justify-content: center;
    
    margin: 0;
  }

  .dropdown-header {
    height: 100%;

    display: flex;
    align-items: center;

    cursor: pointer;
    user-select: none;
    color: $secondaryColor;

    @include transition-fast;

    &:hover {
      opacity: 0.7;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    z-index: 1;

    top: 115px;
    padding: 1rem;

    border-radius: 6px;
    border-top: 1px solid $secondaryColor;
    box-shadow: 0px 8px 8px 0px #0002;
  }

  &:hover .dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@mixin dropdown-open {
  @media (max-width: $tablet) {
    padding-inline-start: 0;
    justify-content: start;

    &.dropdown {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      margin-bottom: 1rem;
    }

    .dropdown-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      position: relative;
      text-align: start;
      top: 0;
      border: none;
      box-shadow: none;
      padding: none;

      border-inline-start: 1px solid $secondaryColor;
      border-radius: 0;
    }

    &.dropdown:hover .dropdown-content {
      display: flex;
    }
  }
}

.header {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;

  z-index: 10;

  .top-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    background-color: $secondaryColor;    
    color: white;
    padding-inline-end: 10px;
    height: 35px;
    font-size: 11px;

    a {
      color: white;
      padding: 0 .3rem;

      display: flex;
      align-items: center;

      @include transition-fast;
      
      &:hover { background-color: #FFF1; }
    }

    ul {
      list-style-type: none;
      
      li {
        list-style-type: none;
        padding: 0 0.4rem;
      }
    }

    .emm-mail {
      
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      i {
        margin: 0 .3rem;
      }

      span {
        @media (max-width: $tablet) {
          display: none;
        }
      }
    }

    .social {
      margin: 0 0 0 1rem;
      display: flex;
      width: 120px;
      height: 100%;

      a {
        margin: 0;
        height: 100%;
        flex-basis: 30px;

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: white;
        }
      }
      .facebook { &:hover { background-color: #3b5998 }}
      .twitter { &:hover { background-color: #1da1f2 }}
      .linkedin { &:hover { background-color: #0077b5 }}
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    height: 120px;
    max-height: 120px;
    padding: 0 20px 0 30px;
    overflow: hidden;

    font-size: 1.1rem;

    border-bottom: 1px solid #0002;

    background-color: $backgroundColor;

    @include transition-fast;

    text-transform: uppercase;

    a {
      @include transition-fast;

      &:hover {
        opacity: 0.7;
      }
    }

    ul {
      list-style-type: none;
      display: flex;

      @media (max-width: $tablet) {
        flex-direction: column;
        padding: 6px;
        display: none;

        font-size: 0.9rem;

        &.mobile-ul {
          display: flex;
        }
      }

      li {
        padding-inline-start: 1rem;
        padding-inline-end: 1rem;

        @include dropdown;
        @include dropdown-open;
      }
    }

    @media (max-width: $tablet) {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      max-height: 100px;
    }

    .mobile-head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 225px;

      @media (max-width: $tablet) {
        width: 100%;
        margin: 5px 0;
      }

      .burger {
        display: none;

        background: none;
        border: none;
        text-decoration: none;

        @include transition-fast;

        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &:focus {
          outline: none;
        }

        i {
          color: $secondaryColor;
          font-size: 2.5rem;
        }

        @media (max-width: $tablet) {
          display: inline-block;
        }
      }

      .logo {
        width: 225px;
        height: auto;

        @media (max-width: $tablet) {
          width: 180px;
        }
      }
    }
  }

  .navigation-mobile-open {
    height: auto;
  }
}

.lang-dropdown {
  @include dropdown;

  a { 
    margin: 0 .3rem;
    img { width: 40px; }
  }

  .dropdown-header {
    height: 35px;
    width: 3rem;
    color: white;
    font-size: .7rem;
    
    justify-content: flex-end;

    img {
      width: auto;
      height: 16px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .dropdown-content {
    top: 35px;
    inset-inline-end: 3px;
    min-width: 0;

    *:hover {
      opacity: .7;
    }
  }

  &:hover .dropdown-content {
    display: none;
  }

  &.open {
    .dropdown-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }  
  }
}

.unpublished-warning {
  position: fixed;
  bottom: 0;
  
  width: 100%;
  height: 35px;

  background-color: #FDD835;
  font-weight: bold;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;

  opacity: 1;

  @include transition-fast;

  i {
    margin-inline-end: 1rem;
  }

  &:hover {
    opacity: 0;
  }
}